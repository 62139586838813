<template>
  <LoadingState v-if="isLoading" />
  <div v-else>
    <template v-if="newComponent">
      <EditSoalCbt
        :listSoal="newDataSoal"
        :isAttendanceSubmitted="isAttendanceSubmitted"
        @save-edit="saveEdit"
        @cancel-edit="cancelEdit"
        :useCard="true"
        :isCbt="true"
      />
    </template>
    <template v-else-if="isPreviewStudent">
      <!-- <PreviewSoalStudent /> -->
      <Card>
        <ExamCBT :isTeacher="isTeacher" :questions="dataSoal" @back-detail="backDetail" />
      </Card>
    </template>
    <template v-else>
      <a-row :gutter="32">
        <a-back-top :visibilityHeight="400" />
        <a-col :sm="24" :lg="14">
          <div class="card-full">
            <div class="detail">
              <div class="cui__utils__heading mb-0">
                <strong>QUESTIONS {{editable ? 'EDITING' : 'DETAILS'}}</strong>
              </div>
              <div class="text-muted mb-3">You can {{editable ? 'edit' : 'see'}} Questions here</div>
              <div class="d-lg-flex flex-wrap">
                <a-form-item class="mr-lg-3">
                  <a-tooltip
                    v-if="dataSoal.length && statusSlot"
                    placement="top"
                    title="This may take up to 1 minute, depending on your internet connection."
                  >
                    <a-button
                      @click.prevent="cekEmpty"
                      v-show="dataSoal.length"
                      :loading="loadingEditQuestion"
                      :type="editable === true ? 'danger' : 'primary'"
                      style="width:100%;height:40px;"
                    >
                      <a-icon v-if="!loadingEditQuestion" :type=" editable ? 'close' : 'edit'" />
                      {{editable ? 'Cancel' : loadingEditQuestion ? 'Please Wait...' : 'Edit Kartu Soal'}}
                    </a-button>
                  </a-tooltip>
                </a-form-item>
                <!-- <a-button
                :loading="loadingUpdateQuestion"
                @click.prevent="updateQuestion"
                v-show="dataSoal.length && editable"
                type="primary"
              >
                <a-icon v-if="!loadingUpdateQuestion" type="save" />
                {{loadingUpdateQuestion ? 'Updating...' : 'Update & Preview'}}
                </a-button>-->
                <a-form-item class="mr-lg-3">
                  <a-button
                    v-if="!editable"
                    @click.prevent="previewStudent"
                    type="default"
                    class="mr-2"
                    style="width:100%;height:40px;"
                  >
                    <a-icon v-if="!loadingExport" type="profile" />Preview as Student
                  </a-button>
                </a-form-item>
                <a-form-item class="mr-lg-3">
                  <a-button
                    :loading="loadingExport"
                    v-if="!editable"
                    @click.prevent="generateReport"
                    type="danger"
                    style="width:100%;height:40px;"
                  >
                    <a-icon v-if="!loadingExport" type="file-pdf" />
                    {{ ExportText }}
                  </a-button>
                </a-form-item>
              </div>
              <hr />
              <div v-if="dataSoal.length">
                <CounterCbt :counterCBT="counterCBT" />
                <template v-if="editable">
                  <div v-for="(item, i) in newDataSoal" :key="i">
                    <a-row :gutter="16">
                      <a-col :span="1">
                        <h3>{{numberQuestion(i)}}.</h3>
                      </a-col>
                      <a-col :span="23">
                        <div class="d-flex flex-row">
                          <a-form-item class="mr-3">
                            <a-tooltip placement="top">
                              <template slot="title">
                                <span>Question Type</span>
                              </template>
                              <a-input
                                read-only
                                size="large"
                                :value="item.tipe_soal"
                                style="width: 170px;"
                              />
                            </a-tooltip>
                          </a-form-item>
                          <a-form-item class="mr-3">
                            <a-tooltip placement="top">
                              <template slot="title">
                                <span>Bobot</span>
                              </template>
                              <a-input-number
                                size="large"
                                :min="1"
                                :max="100"
                                :default-value="item.bobot_soal"
                                @change="handleBobot($event, i)"
                                :disabled="isAttendanceSubmitted"
                                style="width: 80px;"
                              />
                            </a-tooltip>
                          </a-form-item>
                          <a-form-item class="mr-3">
                            <a-tooltip placement="top">
                              <template slot="title">
                                <span>Criteria</span>
                              </template>
                              <a-select
                                size="large"
                                :default-value="item.kriteria"
                                style="width:100px;"
                                @change="handleCriteria($event, i)"
                              >
                                <a-select-option value="MD">MD</a-select-option>
                                <a-select-option value="SD">SD</a-select-option>
                                <a-select-option value="SK">SK</a-select-option>
                              </a-select>
                            </a-tooltip>
                          </a-form-item>
                          <a-form-item v-if="item.tipe_soal === 'Multiple Choice'" class="mr-3">
                            <a-tooltip placement="top">
                              <template slot="title">
                                <span>Correct Answer</span>
                              </template>
                              <a-select
                                size="large"
                                :default-value="item.kunci_jawaban"
                                style="width:60px;"
                                @change="handleCorrectAnswer($event, i)"
                                :disabled="isAttendanceSubmitted"
                              >
                                <a-select-option value="a">a</a-select-option>
                                <a-select-option value="b">b</a-select-option>
                                <a-select-option value="c">c</a-select-option>
                                <a-select-option value="d">d</a-select-option>
                              </a-select>
                            </a-tooltip>
                          </a-form-item>
                          <a-button
                            @click.prevent="removeQuestion(i, item)"
                            class="ml-auto"
                            :loading="loadingEditQuestion"
                            size="large"
                            type="danger"
                          >
                            <a-icon type="file-excel" />Delete Question
                          </a-button>
                        </div>
                        <div class="d-flex flex-row mb-4">
                          <a-tooltip placement="top">
                            <template slot="title">
                              <span>Standar Kompetensi</span>
                            </template>
                            <a-textarea
                              class="mr-3"
                              v-model="item.standar_kompetensi"
                              placeholder="Please fill this column"
                              :auto-size="{ minRows: 3, maxRows: 5 }"
                            />
                          </a-tooltip>
                          <a-tooltip placement="top">
                            <template slot="title">
                              <span>Indikator</span>
                            </template>
                            <a-textarea
                              class="mr-3"
                              v-model="item.indikator"
                              placeholder="Please fill this column"
                              :auto-size="{ minRows: 3, maxRows: 5 }"
                            />
                          </a-tooltip>
                        </div>
                        <ckeditor v-model="item.soal" :editor="editor" :config="editorConfig"></ckeditor>
                        <template v-if="item.tipe_soal === 'Multiple Choice'">
                          <div
                            class="answer py-2"
                            v-for="(itemJawaban, j) in item.jawaban"
                            :key="j"
                          >
                            <ckeditor
                              v-model="item.jawaban[j]"
                              :editor="editor"
                              :config="editorConfig"
                            ></ckeditor>
                          </div>
                        </template>
                      </a-col>
                    </a-row>
                    <hr />
                  </div>
                  <a-popover
                    v-model="visiblePop"
                    title="Choose Question Type"
                    trigger="click"
                    placement="right"
                  >
                    <a-button
                      @click.prevent="addQuestion('Multiple Choice')"
                      class="mb-3 mt-3"
                      size="large"
                      slot="content"
                      type="default"
                      block
                    >
                      <a-icon type="unordered-list" />Multiple Choice
                    </a-button>
                    <a-button
                      @click.prevent="addQuestion('Essay')"
                      class="mb-3"
                      size="large"
                      slot="content"
                      type="default"
                      block
                    >
                      <a-icon type="align-left" />Essay
                    </a-button>
                    <a-button
                      :loading="loadingEditQuestion"
                      size="large"
                      class="ml-auto"
                      type="primary"
                      block
                    >
                      <a-icon type="file-add" />Add Question ?
                    </a-button>
                  </a-popover>
                </template>
                <template v-else>
                  <ListSoalCbt :listSoal="dataSoal" />
                </template>
              </div>
            </div>
          </div>
        </a-col>
        <a-col :sm="24" :lg="10">
          <div
            v-if="role.includes('kurikulum') && detailCbt.status !== 'publish'"
            class="card-full border border-primary"
          >
            <div class="detail">
              <div class="cui__utils__heading mb-0 d-flex flex-row">
                <strong>CBT STATUS</strong>
              </div>
              <div class="text-muted mb-3">You can change status of CBT here</div>
              <hr />
              <a-button
                :loading="loadingApprove"
                type="primary"
                size="large"
                block
                @click.prevent="approveCBT"
                class="mt-4"
              >Approve CBT</a-button>
            </div>
          </div>
          <div class="card-full">
            <div class="detail">
              <div class="cui__utils__heading mb-0">
                <strong>CBT DETAILS</strong>
              </div>
              <div class="text-muted mb-3">You can see CBT here</div>
              <hr />
              <a-row :gutter="16">
                <a-col :span="24">
                  <a-form-item label="Mata Pelajaran">
                    <a-select
                      size="large"
                      style="width:100%;height: 40px;"
                      :default-value="detailCbt.mata_pelajaran.nama"
                      @change="handleSelectMapel"
                      :disabled="true"
                    >
                      <a-select-option
                        v-for="item in mapels"
                        :key="item.id_mata_pelajaran"
                        :value="item.id_mata_pelajaran"
                      >{{item.mata_pelajaran.nama}}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <!-- <a-col v-if="detailCbt.tipe !== 'PH'" :span="24">
                <a-form-item label="Pengawas CBT">
                  <a-select
                    show-search
                    option-filter-prop="children"
                    style="width:100%;height: 40px;"
                    :filter-option="filterOption"
                    :default-value="detailCbt.id_guru"
                    @change="handleSelectPengawas"
                    size="large"
                  >
                    <a-select-option
                      v-for="item in pengawas"
                      :key="item.id"
                      :value="item.id"
                    >{{item.nama}}</a-select-option>
                  </a-select>
                </a-form-item>
                </a-col>-->
                <a-col :span="12" v-if="detailCbt.tipe !== 'PH'">
                  <a-form-item label="Pengawas 1">
                    <a-select
                      show-search
                      option-filter-prop="children"
                      style="width:100%;height: 40px;"
                      :filter-option="filterOption"
                      @change="handleSelectPengawas($event, 1)"
                      size="large"
                      :default-value="selectedPengawas['pengawas1']"
                    >
                      <a-select-option
                        v-for="item in pengawas"
                        :key="item.id"
                        :value="item.id"
                      >{{item.nama}}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="12" v-if="detailCbt.tipe !== 'PH'">
                  <a-form-item label="Pengawas 2">
                    <a-select
                      show-search
                      option-filter-prop="children"
                      style="width:100%;height: 40px;"
                      :filter-option="filterOption"
                      @change="handleSelectPengawas($event, 2)"
                      size="large"
                      :default-value="selectedPengawas['pengawas2']"
                    >
                      <a-select-option
                        v-for="item in pengawas"
                        :key="item.id"
                        :value="item.id"
                      >{{item.nama}}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="12" v-if="detailCbt.tipe !== 'PH'">
                  <a-form-item label="Pengawas 3">
                    <a-select
                      show-search
                      option-filter-prop="children"
                      style="width:100%;height: 40px;"
                      :filter-option="filterOption"
                      @change="handleSelectPengawas($event, 3)"
                      size="large"
                      :default-value="selectedPengawas['pengawas3']"
                    >
                      <a-select-option
                        v-for="item in pengawas"
                        :key="item.id"
                        :value="item.id"
                      >{{item.nama}}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="12" v-if="detailCbt.tipe !== 'PH'">
                  <a-form-item label="Pengawas 4">
                    <a-select
                      show-search
                      option-filter-prop="children"
                      style="width:100%;height: 40px;"
                      :filter-option="filterOption"
                      @change="handleSelectPengawas($event, 4)"
                      size="large"
                      :default-value="selectedPengawas['pengawas4']"
                    >
                      <a-select-option
                        v-for="item in pengawas"
                        :key="item.id"
                        :value="item.id"
                      >{{item.nama}}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="Level">
                    <a-select
                      size="large"
                      style="width:100%;height: 40px;"
                      :default-value="detailCbt.tingkat"
                      @change="handleSelectClass"
                      :disabled="true"
                    >
                      <a-select-option
                        v-for="item in levels"
                        :key="item.id"
                        :value="item.kela.tingkat"
                      >{{item.kela.tingkat}}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="Type">
                    <a-select
                      size="large"
                      style="width:100%;height: 40px;"
                      :default-value="detailCbt.tipe"
                      @change="handleSelectType"
                      :disabled="true"
                    >
                      <a-select-option value="PH">PH</a-select-option>
                      <a-select-option value="PTS">PTS</a-select-option>
                      <a-select-option value="PAS">PAS</a-select-option>
                      <a-select-option value="PTA">PTA</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item label="Start at">
                    <a-date-picker
                      v-model="detailCbt.waktu_ujian_dibuka"
                      :disabled-date="disabledStartDate"
                      show-time
                      format="YYYY-MM-DD HH:mm:ss"
                      @openChange="handleStartOpenChange"
                      style="width:100%"
                      size="large"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item label="End at">
                    <a-date-picker
                      v-model="detailCbt.waktu_ujian_ditutup"
                      :disabled-date="disabledEndDate"
                      show-time
                      format="YYYY-MM-DD HH:mm:ss"
                      :open="endOpen"
                      @openChange="handleEndOpenChange"
                      style="width:100%"
                      size="large"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item label="Intructions">
                    <ckeditor v-model="detailCbt.instruksi" :editor="editor" :config="editorConfig"></ckeditor>
                  </a-form-item>
                </a-col>
                <a-button
                  :loading="loadingAdd"
                  type="primary"
                  size="large"
                  block
                  @click.prevent="generateCBT"
                  :disabled="isFillAll"
                >Save Changes</a-button>
              </a-row>
            </div>
          </div>
          <div class="card-full">
            <div class="detail">
              <div class="cui__utils__heading mb-0 d-flex flex-row">
                <strong>REMOVE CBT</strong>
              </div>
              <div class="text-muted mb-3">You can remove CBT here</div>
              <hr />
              <a-button
                :loading="loadingDelete"
                type="danger"
                size="large"
                block
                @click.prevent="deleteCBT"
                class="mt-4"
              >Remove CBT</a-button>
            </div>
          </div>
        </a-col>
      </a-row>
    </template>

    <vue-html2pdf
      :html-to-pdf-options="pdfOptions"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="false"
      :paginate-elements-by-height="930"
      filename="hahaha"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf"
      @beforeDownload="beforeDownload($event)"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
    >
      <section slot="pdf-content">
        <div class="d-flex justify-content-between align-items-center mx-5">
          <div>
            <img src="@/assets/Logo-Albayan-Islamic-School.png" width="150px" alt />
          </div>
          <div class="text-center">
            <h3>LEMBAGA PENDIDIKAN ISLAM AL BAYAN</h3>
            <h3>SMP AL BAYAN ISLAMIC SCHOOL</h3>
            <p>Jl. Basoka Raya, Kavling Hankam Blok R No. 6 Joglo,</p>
            <p>Jakarta Barat, DKI Jakarta</p>
            <p>Telp. (021) 584 0929 Fax. (021) 584 0929, 584 8854</p>
          </div>
          <div>
            <img src="@/assets/dokumen.png" width="150px" height="70px" alt />
          </div>
        </div>
        <div class="mx-5">
          <hr style="border: 1px solid black;" />
          <h3 class="text-center">{{detailCbt.tipePanjang}} ({{detailCbt.tipe}})</h3>
          <h3
            class="text-center"
          >SEMESTER {{detailCbt.tahun_ajaran.semester.toUpperCase()}} - TAHUN PELAJARAN {{detailCbt.tahun_ajaran.tahun}}</h3>
          <table border="0" class="mt-3">
            <tr>
              <td>Hari, Tanggal</td>
              <td>:</td>
              <td>{{detailCbt.waktu_ujian_dibuka}}</td>
            </tr>
            <tr>
              <td width="45%">Mata Pelajaran</td>
              <td>:</td>
              <td>{{detailCbt.mata_pelajaran.nama}}</td>
            </tr>
            <tr>
              <td>Kelas</td>
              <td>:</td>
              <td>{{detailCbt.tingkat}}</td>
            </tr>
            <tr>
              <td>Durasi</td>
              <td>:</td>
              <td>{{detailCbt.durasi}} (menit)</td>
            </tr>
          </table>
          <hr style="border: 1px solid black;" />
          <span style="font-size:8px;" v-html="detailCbt.instruksi"></span>
          <hr style="border: 1px solid black;" />
        </div>
        <div v-for="(item, i) in dataSoal" :key="i" class="mx-5">
          <div class="d-flex flex-row mb-4">
            <span class="font-weight-bold">{{numberQuestion(i)}}.</span>
            <div class="ml-2">
              <span class="soal" v-html="item.soal"></span>
              <template v-if="item.tipe_soal === 'Multiple Choice'">
                <div class="mt-2" v-for="(itemJawaban, j) in item.jawaban" :key="j">
                  <span class="soal" v-html="itemJawaban"></span>
                </div>
                <div class="mt-3 font-weight-bold">
                  <span
                    v-if="item.tipe_soal === 'Multiple Choice'"
                    class="mr-4"
                  >Kunci Jawaban : {{item.kunci_jawaban}}</span>
                  <span class="mr-4">Bobot Soal : {{item.bobot_soal}}</span>
                  <span class="mr-4">Kriteria : {{item.kriteria}}</span>
                </div>
              </template>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>
    <vue-html2pdf
      :html-to-pdf-options="pdfOptions2"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="false"
      :paginate-elements-by-height="1000"
      :filename="fileNameKartuSoal"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf2"
      @beforeDownload="beforeDownload($event)"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
    >
      <section slot="pdf-content">
        <div v-for="(item, index) in dataSoal" :key="index">
          <div class="d-flex align-items-center mx-5 mt-4">
            <div>
              <img src="@/assets/logo_2.jpeg" width="150px" alt />
            </div>
            <div class="text-left ml-4">
              <h3>SMP AL BAYAN ISLAMIC SCHOOL JAKARTA</h3>
              <p>Jl. Basoka Raya, Kavling Hankam Blok R No. 6 Joglo</p>
              <p>E-Mail : smpalbayan2015@gmail.com</p>
              <p>Website : www.albayan.sch.id</p>
            </div>
          </div>
          <div class="mx-5">
            <hr style="border: 1px solid black;" />
            <h3 class="text-center">DINAS PENDIDIKAN PROVINSI DKI JAKARTA</h3>
            <h3 class="text-center">PENILAIAN AKHIR SEMESTER (PAS) GANJIL</h3>
            <h3 class="text-center">SMP AL BAYAN ISLAMIC SCHOOL</h3>
            <h3 class="text-center">KARTU SOAL</h3>
            <table border="0" class="mt-3">
              <tr>
                <td>Jenis Sekolah</td>
                <td>:</td>
                <td width="35%">SMP</td>
                <td>Nama Penyusun</td>
                <td>:</td>
                <td>{{detailCbt.cbt_history.createdBy}}</td>
              </tr>
              <tr>
                <td>Kelas</td>
                <td>:</td>
                <td>{{detailCbt.tingkat}}</td>
                <td>Asal Sekolah</td>
                <td>:</td>
                <td>AL BAYAN ISLAMIC SCHOOL</td>
              </tr>
            </table>
            <hr style="border: 1px solid black;" />
            <table border="1">
              <tr>
                <td width="35%">
                  <span class="font-weight-bold">STANDAR KOMPETENSI LULUSAN</span>
                </td>
                <td width="35%">
                  <span class="font-weight-bold">INDIKATOR</span>
                </td>
                <td>
                  <span class="font-weight-bold">NO SOAL</span>
                </td>
                <td>
                  <span class="font-weight-bold">KUNCI JAWABAN OBYEKTIF</span>
                </td>
              </tr>
              <tr>
                <td>{{item.standar_kompetensi}}</td>
                <td>{{item.indikator}}</td>
                <td>{{numberQuestion(index)}}</td>
                <td>{{item.kunci_jawaban}}</td>
              </tr>
              <tr>
                <td colspan="4">&nbsp;</td>
              </tr>
              <tr>
                <td colspan="2">
                  <span class="font-weight-bold">BUTIR SOAL</span>
                </td>
                <td colspan="2">
                  <span class="font-weight-bold">JAWABAN</span>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <span v-html="item.soal"></span>
                </td>
                <td colspan="2">
                  <!-- {{item.jawaban}} -->
                  <span class="mt-3" v-for="(itemJawaban, j) in item.jawaban" :key="j">
                    <span class="soal" v-html="itemJawaban"></span>
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import moment from 'moment'
import Card from '@/components/app/Card'
import LoadingState from '@/components/app/LoadingState'
import VueHtml2pdf from 'vue-html2pdf'
import ListSoalCbt from '@/components/app/CbtGuru/ListSoalCbt'
import CounterCbt from '@/components/app/CbtGuru/CounterCbt'
import EditSoalCbt from '@/components/app/CbtGuru/EditSoalCbt'
import ExamCBT from '@/components/app/ExamCBT'
// import PreviewSoalStudent from '@/components/app/CbtGuru/PreviewSoalStudent'

export default {
  data() {
    return {
      isAttendanceSubmitted: false,
      isLoading: true,
      editable: false,
      loadingAdd: false,
      loadingEditQuestion: false,
      endOpen: false,
      visiblePop: false,
      loadingApprove: false,
      statusSlot: false,
      loadingExport: false,
      loadingUpdateQuestion: false,
      loadingDelete: false,
      newComponent: false,
      isPreviewStudent: false,
      isTeacher: true,
      ExportText: 'Export Kartu Soal',
      detailCbt: {},
      dataSoal: [],
      newDataSoal: [],
      pilihans: ['A', 'B', 'C', 'D'],
      mapel: [],
      levels: [],
      pengawas: [],
      selectedPengawas: {
        pengawas1: '',
        pengawas2: '',
        pengawas3: '',
        pengawas4: '',
      },
      counterCBT: {
        counterQuestions: 0,
        counterEssay: 0,
        counterMultipleChoice: 0,
        counterEmpty: 0,
        counterBobot: 0,
      },
      pdfOptions: {
        margin: [10, 0, 0, 0],
        filename: '',
        html2canvas: {
          letterRendering: true,
          useCORS: true,
        },
      },
      pdfOptions2: {
        margin: [0, 0, 0, 0],
        filename: '',
        html2canvas: {
          letterRendering: true,
          useCORS: true,
        },
      },
    }
  },
  components: {
    Card,
    LoadingState,
    VueHtml2pdf,
    CounterCbt,
    ListSoalCbt,
    EditSoalCbt,
    ExamCBT,
    // PreviewSoalStudent,
  },
  methods: {
    backDetail() {
      this.isPreviewStudent = false
    },
    cancelEdit() {
      this.newComponent = false
    },
    saveEdit(payload) {
      const idCbt = this.$route.params.id
      this.newDataSoal = payload
      const cbtSoalBundle = this.newDataSoal.map(row => {
        if (this.isAttendanceSubmitted) {
          return {
            id: row.id,
            nomor_soal: 0,
            soal: row.soal,
            jawaban: row.tipe_soal === 'Essay' ? null : JSON.stringify(row.jawaban),
            kriteria: row.kriteria,
            aspek: '',
            tipe_soal: row.tipe_soal,
            kunci_jawaban: row.kunci_jawaban,
            bobot_soal: row.bobot_soal,
            indikator: row.indikator,
            standar_kompetensi: row.standar_kompetensi,
          }
        } else {
          return {
            nomor_soal: 0,
            soal: row.soal,
            jawaban: row.tipe_soal === 'Essay' ? null : JSON.stringify(row.jawaban),
            kriteria: row.kriteria,
            aspek: '',
            tipe_soal: row.tipe_soal,
            kunci_jawaban: row.kunci_jawaban,
            bobot_soal: row.bobot_soal,
            indikator: row.indikator,
            standar_kompetensi: row.standar_kompetensi,
          }
        }
      })
      this.$store.dispatch('cbt/PUT_CBT_SOAL', {
        idCbt,
        idGuru: this.user.id,
        cbtSoalBundle,
      })
        .then(res => {
          this.newComponent = false
          if (res === 'SUCCESS_EDIT_CBT_SOAL' || res === 'SUCCESS_EDIT_ANSWERED_CBT_SOAL') {
            this.resetCounter()
            this.fetchDataCbtDetail()
            this.$notification.success({
              message: 'Success.',
              description: 'Kartu Soal has been updated',
            })
          } else {
            this.$notification.error({
              message: 'Error.',
              description: 'Kartu Soal has not been updated',
            })
          }
        })
      // this.resetCounter()
      // this.startCounter()
      // console.log(payload)
    },
    previewStudent() {
      this.isPreviewStudent = true
    },
    generateReport() {
      this.loadingExport = true
      this.ExportText = 'Exporting...'
      this.$refs.html2Pdf.generatePdf()
      this.$refs.html2Pdf2.generatePdf()
    },
    generateReport2() {
      this.$refs.html2Pdf2.generatePdf()
    },
    onProgress(event) {
      this.loadingExport = true
      this.ExportText = 'Exporting...'
      // console.log('Progress  ', event)
    },
    hasStartedGeneration() {
      console.log('Start Generate')
    },
    hasGenerated(event) {
      console.log('Generated', event)
    },
    async beforeDownload ({ html2pdf, options, pdfContent }) {
      await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setFontSize(10)
          pdf.setTextColor(150)
          pdf.text('Hasan Educational Technology', (pdf.internal.pageSize.getWidth() * 0.50), (pdf.internal.pageSize.getHeight() - 2))
          pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 2))
        }
      }).save()
      this.loadingExport = false
      this.ExportText = 'Export Kartu Soal to PDF'
    },
    numberQuestion(index) {
      return ++index
    },
    handleBobot(value, i) {
      this.newDataSoal[i].bobot_soal = value
      this.counterCBT.counterBobot = 0
      this.newDataSoal.forEach(row => {
        this.counterCBT.counterBobot += parseFloat(row.bobot_soal)
      })
    },
    handleCriteria(value, i) {
      this.newDataSoal[i].kriteria = value
    },
    handleCorrectAnswer(value, i) {
      this.newDataSoal[i].kunci_jawaban = value
    },
    removeQuestion(index, soal) {
      // console.log(index)
      if (this.isAttendanceSubmitted) {
        this.$notification.error({
          message: 'Error',
          description:
            'You cannot delete this question, when students has already answered.',
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure to remove this question ?</div>
          ),
          onOk: () => {
            this.counterCBT.counterQuestions -= 1
            this.counterCBT.counterBobot -= soal.bobot_soal
            if (soal.tipe_soal === 'Multiple Choice') {
              this.counterCBT.counterMultipleChoice -= 1
            } else {
              this.counterCBT.counterEssay -= 1
            }
            this.newDataSoal.splice(index, 1)
            this.$notification.success({
              message: 'Success',
              description:
                'This Question has been removed',
            })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'danger',
          okText: 'Remove',
        })
      }
    },
    addQuestion(type) {
      const idCbt = this.$route.params.id
      const data = {}
      this.visiblePop = false
      if (this.isAttendanceSubmitted) {
        this.$notification.error({
          message: 'Error',
          description:
            'You cannot add the question, when students has already answered.',
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure to add {type} question ?</div>
          ),
          onOk: () => {
            if (type === 'Multiple Choice') {
              data.jawaban = ['a. type your choice here', 'b. type your choice here', 'c. type your choice here', 'd. type your choice here']
              data.kunci_jawaban = 'a'
              this.counterCBT.counterMultipleChoice += 1
            } else {
              data.jawaban = ''
              this.counterCBT.counterEssay += 1
            }
            data.id_cbt = idCbt
            data.tipe_soal = type
            data.soal = 'Type your question here...'
            data.bobot_soal = 1
            data.kriteria = 'SD'
            this.counterCBT.counterQuestions += 1
            this.counterCBT.counterBobot += data.bobot_soal
            this.newDataSoal.push(data)
            this.$notification.success({
              message: 'Success',
              description:
                'This Question has been added',
            })
          },
          onCancel: () => {
            this.visiblePop = false
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: 'Add',
        })
      }
    },
    updateQuestion() {
      if (this.counterCBT.counterBobot !== 100) {
        this.$notification.error({
          message: 'Sorry.',
          description: `Total Bobot Soal is ${this.counterCBT.counterBobot}, Make sure total Bobot Soal is 100.`,
        })
      } else {
        this.loadingUpdateQuestion = true
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure you want to update the questions ?</div>
          ),
          onOk: () => {
            const idCbt = this.$route.params.id
            const cbtSoalBundle = this.newDataSoal.map(row => {
              if (this.isAttendanceSubmitted) {
                return {
                  id: row.id,
                  nomor_soal: 0,
                  soal: row.soal,
                  jawaban: row.tipe_soal === 'Essay' ? null : JSON.stringify(row.jawaban),
                  kriteria: row.kriteria,
                  aspek: '',
                  tipe_soal: row.tipe_soal,
                  kunci_jawaban: row.kunci_jawaban,
                  bobot_soal: row.bobot_soal,
                  indikator: row.indikator,
                  standar_kompetensi: row.standar_kompetensi,
                }
              } else {
                return {
                  nomor_soal: 0,
                  soal: row.soal,
                  jawaban: row.tipe_soal === 'Essay' ? null : JSON.stringify(row.jawaban),
                  kriteria: row.kriteria,
                  aspek: '',
                  tipe_soal: row.tipe_soal,
                  kunci_jawaban: row.kunci_jawaban,
                  bobot_soal: row.bobot_soal,
                  indikator: row.indikator,
                  standar_kompetensi: row.standar_kompetensi,
                }
              }
            })
            this.$store.dispatch('cbt/PUT_CBT_SOAL', {
              idCbt,
              idGuru: this.user.id,
              cbtSoalBundle,
            })
              .then(res => {
                this.loadingUpdateQuestion = false
                this.editable = false
                if (res === 'SUCCESS_EDIT_CBT_SOAL') {
                  this.resetCounter()
                  this.fetchDataCbtDetail()
                  this.$notification.success({
                    message: 'Success.',
                    description: 'Kartu Soal has been updated',
                  })
                } else {
                  this.$notification.error({
                    message: 'Error.',
                    description: 'Kartu Soal has not been updated',
                  })
                }
              })
          },
          onCancel: () => {
            this.loadingUpdateQuestion = false
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: 'Update',
        })
      }
    },
    handleSelectMapel(value) {
      this.detailCbt.id_mata_pelajaran = value
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleSelectPengawas(value, no) {
      // this.detailCbt.id_pengawas = value
      this.selectedPengawas[`pengawas${no}`] = value
    },
    handleSelectClass(value) {
      this.detailCbt.tingkat = value
    },
    handleSelectType(value) {
      this.detailCbt.tipe = value
    },
    disabledStartDate(startValue) {
      const endValue = this.detailCbt.waktu_ujian_ditutup
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate(endValue) {
      const startValue = this.detailCbt.waktu_ujian_dibuka
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    resetCounter() {
      this.counterCBT.counterQuestions = 0
      this.counterCBT.counterEssay = 0
      this.counterCBT.counterMultipleChoice = 0
      this.counterCBT.counterBobot = 0
      this.counterCBT.counterEmpty = 0
    },
    fetchDataMapel() {
      this.$store.dispatch('cbt/FETCH_MAPEL_BY_ID_TEACHER', { idGuru: this.user.id })
        .then(data => {
          this.mapels = data.mapel
          this.levels = data.tingkat
        })
    },
    fetchDataPengawas() {
      this.$store.dispatch('cbt/FETCH_PENGAWAS')
        .then(data => {
          this.pengawas = data
        })
    },
    fetchDataCbt() {
      const idCbt = this.$route.params.id
      const idGuru = this.user.id
      this.isLoading = true
      this.$store.dispatch('cbt/FETCH_CBT_BY_ID', { idCbt, idGuru })
        .then(res => {
          this.isLoading = false
          this.detailCbt = { ...res.cbt }
          this.detailCbt.waktu_ujian_dibuka = moment(this.detailCbt.waktu_ujian_dibuka).format('DD MMM YYYY HH:mm:ss')
          this.detailCbt.waktu_ujian_ditutup = moment(this.detailCbt.waktu_ujian_ditutup).format('DD MMM YYYY HH:mm:ss')
          // this.dataCBT.instruksi = this.detailCbt.instruksi
          // console.log(res.isAttendanceSubmitted)
          this.isAttendanceSubmitted = res.isAttendanceSubmitted
          // if (res.isAttendanceSubmitted) {
          //   this.isAttendanceSubmitted = true
          // } else {
          //   this.isAttendanceSubmitted = false
          // }
          const tipe = res.cbt.tipe
          // console.log(tipe)
          let detailTipe = ''
          if (tipe === 'PH') {
            detailTipe = 'PENILAIAN HARIAN'
          } else if (tipe === 'PTS') {
            detailTipe = 'PENILAIAN TENGAH SEMESTER'
          } else if (tipe === 'PAS') {
            detailTipe = 'PENILAIAN AKHIR SEMESTER'
          } else if (tipe === 'PAT') {
            detailTipe = 'PENILAIAN AKHIR TAHUN'
          }
          // console.log(res.listPengawas)
          // res.listPengawas.forEach(el => {})
          if (res.listPengawas) {
            res.listPengawas.forEach((el, index) => {
              this.selectedPengawas[`pengawas${++index}`] = el.id
            })
          }
          // console.log(this.selectedPengawas)
          this.detailCbt.tipePanjang = detailTipe
          this.pdfOptions.filename = `SOAL ${this.detailCbt.tipe}-${this.detailCbt.mata_pelajaran.nama}-${this.detailCbt.tingkat}.pdf`
          this.pdfOptions2.filename = `KARTU SOAL ${this.detailCbt.tipe}-${this.detailCbt.mata_pelajaran.nama}-${this.detailCbt.tingkat}.pdf`
        })
    },
    fecthIsSlot() {
      const idGuru = this.user.id
      const idCbt = this.$route.params.id
      this.$store.dispatch('cbt/FETCH_CBT_SLOT', { idCbt, idGuru })
        .then(res => {
          if (res === 'TRUE_GURU_MAPEL') {
            this.statusSlot = true
          } else {
            this.statusSlot = false
          }
        })
    },
    fetchDataCbtDetail() {
      const idCbt = this.$route.params.id
      this.$store.dispatch('cbt/FETCH_CBT_ATTENDANCES_BY_ID', { idCbt, page: '', idKelas: '' })
        .then(res => {
          this.dataSoal = res.cbt.cbt_soals.map(row => {
            return {
              id: row.id,
              id_cbt: row.id_cbt,
              tipe_soal: row.tipe_soal,
              soal: row.soal,
              jawaban: row.tipe_soal === 'Multiple Choice' ? JSON.parse(row.jawaban) : row.jawaban,
              kunci_jawaban: row.kunci_jawaban,
              bobot_soal: row.bobot_soal,
              kriteria: row.kriteria,
              indikator: row.indikator,
              standar_kompetensi: row.standar_kompetensi,
            }
          })
          this.counterCBT.counterQuestions = this.dataSoal.length
          this.dataSoal.forEach(row => {
            this.counterCBT.counterBobot += parseInt(row.bobot_soal)
            if (row.soal && (row.jawaban === '""' || row.jawaban === null)) {
              this.counterCBT.counterEssay++
            } else if (row.soal && row.jawaban && row.kunci_jawaban) {
              this.counterCBT.counterMultipleChoice++
            // } else {
            //   this.counterCBT.counterEmpty++
            }
          })
        })
    },
    cekEmpty() {
      if (!this.editable && this.dataSoal.length) {
        if (this.counterCBT.counterEmpty > 0) {
          this.editable = false
          this.$notification.error({
            message: 'Sorry.',
            description: `There are ${this.counterCBT.counterEmpty} questions that are still empty, Please recheck the Kartu Soal document`,
          })
        } else if (this.counterCBT.counterBobot !== 100) {
          this.editable = false
          this.$notification.error({
            message: 'Sorry.',
            description: `Total Bobot Soal is ${this.counterCBT.counterBobot}, Make sure total Bobot Soal is 100.`,
          })
        } else {
          this.loadingEditQuestion = true
          const delay = t => new Promise(resolve => setTimeout(resolve, t))
          delay(100).then(() => {
            // this.editable = true
            this.newComponent = true
            this.loadingEditQuestion = false
            this.newDataSoal = this.dataSoal
          })
        }
      } else if (this.editable && this.dataSoal.length) {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure you want to cancel editing ? your changes will be lost</div>
          ),
          onOk: () => {
            this.editable = false
            this.resetCounter()
            this.fetchDataCbtDetail()
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: 'Yes',
        })
      }
    },
    generateCBT() {
      if (this.counterCBT.counterEmpty > 0) {
        this.$notification.error({
          message: 'Sorry.',
          description: `There are ${this.counterCBT.counterEmpty} questions that are still empty, Please recheck the Kartu Soal document`,
        })
      } else if (this.counterCBT.counterBobot !== 100) {
        this.$notification.error({
          message: 'Sorry.',
          description: `Total Bobot Soal is ${this.counterCBT.counterBobot}, Make sure total Bobot Soal is 100.`,
        })
      } else if (this.editable) {
        this.$notification.error({
          message: 'Sorry.',
          description: 'You still editing the Kartu Soal, Make sure you have saved any changes.',
        })
      } else if (this.findDuplicatesPengawas) {
        this.$notification.error({
          message: 'Sorry.',
          description: 'There are duplicated pengawas, Make sure you have a diference pengawas.',
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure want to save this CBT ?</div>
          ),
          onOk: () => {
            this.loadingAdd = true
            if (this.counterCBT.counterMultipleChoice !== 0 && this.counterCBT.counterEssay !== 0) {
              this.detailCbt.tipe_cbt = 'Multiple Choice & Essay'
            } else if (this.counterCBT.counterMultipleChoice !== 0 && this.counterCBT.counterEssay === 0) {
              this.detailCbt.tipe_cbt = 'Multiple Choice'
            } else if (this.counterCBT.counterMultipleChoice === 0 && this.counterCBT.counterEssay !== 0) {
              this.detailCbt.tipe_cbt = 'Essay'
            }
            this.detailCbt.waktu_ujian_dibuka = moment(this.detailCbt.waktu_ujian_dibuka).format('YYYY-MM-DD HH:mm:ss')
            this.detailCbt.waktu_ujian_ditutup = moment(this.detailCbt.waktu_ujian_ditutup).format('YYYY-MM-DD HH:mm:ss')
            const idCbt = this.$route.params.id
            this.detailCbt.id_guru = this.user.id
            const dataCBT = this.detailCbt
            const pengawas = Object.values(this.selectedPengawas)
            this.$store.dispatch('cbt/PUT_CBT', {
              idCbt,
              idGuru: this.user.id,
              dataCBT,
              pengawas,
            })
              .then(res => {
                this.loadingAdd = false
                // console.log('Res view', res)
                if (res === 'SUCCESS_EDIT_CBT') {
                  this.dataCBT = {}
                  this.excelData = []
                  this.$notification.success({
                    message: 'Success.',
                    description: 'CBT has been saved',
                  })
                  this.$router.push({ name: 'Cbt Teacher' })
                  this.$store.commit('menu/SET_STATE', {
                    currentMenu: ['Cbt Teacher'],
                  })
                } else if (res === 'Success generate cbt, cbt_soal, cbt_attendance') {
                  this.dataCBT = {}
                  this.excelData = []
                  this.$notification.success({
                    message: 'Success.',
                    description: 'CBT has been saved',
                  })
                  this.$router.push({ name: 'Cbt Teacher' })
                  this.$store.commit('menu/SET_STATE', {
                    currentMenu: ['Cbt Teacher'],
                  })
                } else {
                  this.$notification.error({
                    message: 'Error.',
                    description: 'CBT has not been saved',
                  })
                }
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: 'Save',
        })
      }
      // console.log(this.dataCBT)
    },
    approveCBT() {
      const idCbt = this.$route.params.id
      const idGuru = this.user.id
      this.loadingApprove = true
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure to approve this CBT ?</div>
        ),
        onOk: () => {
          this.$store.dispatch('cbt/APPROVE_CBT', {
            idCbt,
            idGuru,
          })
            .then(res => {
              this.loadingApprove = false
              if (res === 'SUCCESS_PUBLISH_CBT') {
                this.$notification.success({
                  message: 'Success.',
                  description: 'CBT has been approved',
                })
                this.$router.push({ name: 'Cbt Teacher' })
                this.$store.commit('menu/SET_STATE', {
                  currentMenu: ['Cbt Teacher'],
                })
              } else {
                this.$notification.error({
                  message: 'Error.',
                  description: 'CBT cannot be approved',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingApprove = false
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Approve',
      })
    },
    deleteCBT() {
      this.loadingDelete = true
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure to remove this question ? your data will be lost</div>
        ),
        onOk: () => {
          const idCbt = this.$route.params.id
          this.$store.dispatch('cbt/DELETE_CBT', {
            idCbt,
          })
            .then(res => {
              this.loadingDelete = false
              if (res === `Success delete guru CBT by id ${idCbt}`) {
                this.$notification.success({
                  message: 'Success',
                  description:
                    'CBT has been removed',
                })
                this.$router.push({ name: 'Cbt Teacher' })
                this.$store.commit('menu/SET_STATE', {
                  currentMenu: ['Cbt Teacher'],
                })
              } else {
                this.$notification.error({
                  message: 'Error.',
                  description: 'CBT cannot be deleted',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDelete = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Remove',
      })
    },
  },
  computed: {
    user() { return this.$store.state.user.user },
    role() {
      return this.$store.state.user.role
    },
    editor() {
      return this.$store.state.ckEditor.editor
    },
    editorConfig() {
      return this.$store.state.ckEditor.editorConfig
    },
    findDuplicatesPengawas() {
      if (this.detailCbt.tipe !== 'PH') {
        const arr = Object.values(this.selectedPengawas)
        const findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index)
        const duplicates = findDuplicates(arr)
        console.log(duplicates.length)
        if (duplicates.length) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    isFillCBT() {
      if (this.detailCbt.id_mata_pelajaran && this.detailCbt.tingkat && this.detailCbt.tipe && this.detailCbt.waktu_ujian_dibuka && this.detailCbt.waktu_ujian_ditutup && this.detailCbt.instruksi) {
        return true
      } else {
        return false
      }
    },
    isFillAll() {
      if (this.dataSoal.length && this.isFillCBT) {
        return false
      } else {
        return true
      }
    },
    fileNameKartuSoal() {
      return `Kartu Soal ${this.detailCbt.tipe}-${this.detailCbt.mata_pelajaran.nama}-${this.detailCbt.tingkat}.pdf`
    },
  },
  created() {
    this.fetchDataMapel()
    this.fetchDataPengawas()
    this.fetchDataCbt()
    this.fetchDataCbtDetail()
    this.fecthIsSlot()
  },
}
</script>

<style scoped>
.card-full {
  padding: 20px 20px;
  margin-top: 60px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #0419301a;
}

.detail {
  padding: 15px;
}
</style>
