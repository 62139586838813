<template>
  <div>
    <hr />
    <div v-for="(item, i) in listSoal" :key="i">
      <a-row :gutter="0">
        <a-col :span="2">
          <h3>{{ numberQuestion(i) }}.</h3>
        </a-col>
        <a-col :span="22">
          <span class="soal" v-html="item.soal ? item.soal : 'Empty'"></span>
          <div class="mt-3" v-for="(itemJawaban, j) in item.jawaban" :key="j">
            <span class="soal" v-html="itemJawaban"></span>
          </div>
          <div class="mt-3 font-weight-bold">
            <span v-if="item.tipe_soal === 'Multiple Choice'" class="mr-4"
              >Kunci Jawaban : {{ item.kunci_jawaban }}</span
            >
            <span class="mr-4">Bobot Soal : {{ item.bobot_soal }}</span>
            <span class="mr-4">Kriteria : {{ item.kriteria }}</span>
          </div>
        </a-col>
      </a-row>
      <hr />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListSoalCbt',
  props: {
    listSoal: {
      type: Array,
      required: true,
    },
  },
  methods: {
    numberQuestion(index) {
      return ++index
    },
  },
}
</script>

<style>
</style>
